require("lightgallery.js/dist/js/lightgallery.js");

export default class ImageGallery {
  init() {
    let controlElement = "";
    let methodsInstance;
    const elements = document.querySelectorAll(".ImageGallery--slideshow");
    controlElement =
      '<svg class="SvgIcon" aria-hidden="true" role="presentation" shape-rendering="geometricPrecision" style="--svg-size: 24px"><use class="icon-arrow-left-icon" xlink:href="/images/icons.svg#icon-arrow-left-icon"></use></svg>';

    elements.forEach((gallery) => {
      lightGallery(gallery, {
        selector: ".ImageGalleryItem__image",
        getCaptionFromTitleOrAlt: false,
        counter: false,
        download: false,
        prevHtml: controlElement,
        nextHtml: controlElement,
        preload: 1
      });
    });
  }
}
