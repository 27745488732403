export default class DividerText {
  init() {

    const elements = document.querySelectorAll('.item.repeat');

    elements.forEach((element) => {
      var text = element.innerHTML;
      text += '';
      element.innerHTML = '</span>'+element.innerHTML+'</span>';
      var width = element.offsetWidth;
      var parentWidth = element.parentNode.offsetWidth;
      var numReps = Math.floor(parentWidth / width);
      element.innerHTML = text.repeat(numReps);
      
    });

    /*
    //$('.divider.text').ready(function() {
      jQuery('.item.repeat').each(function() {
        var $this = $(this);
        var text = $this.html();
        text += '';
        $this.html('</span>'+$this.html()+'</span>');
        var width = $this.outerWidth();
        var parentWidth = $this.parent('div').outerWidth();
        var numReps = Math.floor(parentWidth / width);
        $this.html(text.repeat(numReps));
      });
    //});
    */
  }
}