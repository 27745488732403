import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([ Navigation, Pagination ]);

export default class Carousel {
  init() {
    var windowSize = window.matchMedia("(max-width: 940px)")
    //const elements = document.querySelectorAll('.ListGrid:not(.four-items)');
    const elementsImageGridOne = document.querySelectorAll('.ImageGrid.oneImg');
    const elementsImageGrid = document.querySelectorAll('.ImageGrid:not(.oneImg)');
    const elementsTwo = document.querySelectorAll('.ListGrid.two-items');
    const elementsThree = document.querySelectorAll('.ListGrid.three-items');
    const elementsFour = document.querySelectorAll('.ListGrid.four-items');
    const elementsServices= document.querySelectorAll('.homeServices .services-quick-container .Carousel__wrapper');
    const elementsFilters= document.querySelectorAll('.filtersBlock .filters-container .Carousel__wrapper');

    //document.addEventListener('DOMContentLoaded', function() {
     
      elementsImageGridOne.forEach((element) => {
        const nextArrow = element.querySelector('.Carousel__nav-next');
        const prevArrow = element.querySelector('.Carousel__nav-prev');
        const navCarousel = element.querySelector('.Carousel__nav');
        const slides = element.querySelectorAll('.swiper-wrapper .swiper-slide');
        //const pagination = element.querySelector('.Carousel__pagination');
        const viewOneImg = parseInt(element.getAttribute('data-view'), 10) || 1;
        //alert('.ImageGrid.oneImg');
        this.swiper = new Swiper(element, {
          slidesPerView: 1,
          loop: false,
          //focusOnSelect:true,
          spaceBetween: 12,
          breakpoints: {
            1: {
              slidesPerView: 1,
              spaceBetween: 12,
            },
            768: {
              slidesPerView: viewOneImg,
              spaceBetween: 24,
            }
          },
          navigation: {
            nextEl: nextArrow,
            prevEl: prevArrow
          },
          //pagination: {
            //el: pagination,
            //clickable: true,
          //},
          on: {
            breakpoint: function (e) {
              //console.log('swiper per view - ImageGridOne: ', e.passedParams.breakpoints[e.currentBreakpoint].slidesPerView);
              //console.log('total slides - ImageGridOne: ', slides.length);
              if ( e.passedParams.breakpoints[e.currentBreakpoint].slidesPerView >= slides.length) {
                navCarousel.style.display = 'none';
              } else {
                navCarousel.style.display = 'flex';
              }
            },
          },
        });
      });

      elementsImageGrid.forEach((element) => {
        const nextArrow = element.querySelector('.Carousel__nav-next');
        const prevArrow = element.querySelector('.Carousel__nav-prev');
        const navCarousel = element.querySelector('.Carousel__nav');
        const slides = element.querySelectorAll('.swiper-wrapper .swiper-slide');
        //const pagination = element.querySelector('.Carousel__pagination');
        const viewRestImg = parseInt(element.getAttribute('data-view'), 10) || 1;
        //alert('.ImageGrid:not(.oneImg)');
        this.swiper = new Swiper(element, {
          slidesPerView: 2,
          loop: false,
          //focusOnSelect:true,
          spaceBetween: 12,
          breakpoints: {
            1: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            768: {
              slidesPerView: viewRestImg,
              spaceBetween: 24,
            }
          },
          navigation: {
            nextEl: nextArrow,
            prevEl: prevArrow
          },
          //pagination: {
            //el: pagination,
            //clickable: true,
          //},
          on: {
            breakpoint: function (e) {
              //console.log('swiper per view - ImageGrid: ', e.passedParams.breakpoints[e.currentBreakpoint].slidesPerView);
              //console.log('total slides - ImageGrid: ', slides.length);
              if ( e.passedParams.breakpoints[e.currentBreakpoint].slidesPerView >= slides.length) {
                navCarousel.style.display = 'none';
              } else {
                navCarousel.style.display = 'flex';
              }
            },
          },
        });
      });

      /*elements.forEach((element) => {
        const nextArrow = element.querySelector('.Carousel__nav-next');
        const prevArrow = element.querySelector('.Carousel__nav-prev');
        const navCarousel = element.querySelector('.Carousel__nav');
        const slides = element.querySelectorAll('.swiper-wrapper .swiper-slide');
        //const pagination = element.querySelector('.Carousel__pagination');
        const view = parseInt(element.getAttribute('data-view'), 10) || 1;

        this.swiper = new Swiper(element, {
          slidesPerView: 1,
          loop: false,
          //focusOnSelect:true,
          spaceBetween: 12,
          breakpoints: {
            1: {
              slidesPerView: 1,
              spaceBetween: 12,
            },
            768: {
              slidesPerView: view,
              spaceBetween: 24,
            }
          },
          navigation: {
            nextEl: nextArrow,
            prevEl: prevArrow
          },
          //pagination: {
            //el: pagination,
            //clickable: true,
          //},
          on: {
            breakpoint: function (e) {
              //console.log('swiper per view - list: ', e.passedParams.breakpoints[e.currentBreakpoint].slidesPerView);
              //console.log('total slides - list: ', slides.length);
              if ( e.passedParams.breakpoints[e.currentBreakpoint].slidesPerView >= slides.length) {
                navCarousel.style.display = 'none';
              } else {
                navCarousel.style.display = 'flex';
              }
            },
          },
        });
      });*/

      elementsTwo.forEach((element) => {
        const nextArrow = element.querySelector('.Carousel__nav-next');
        const prevArrow = element.querySelector('.Carousel__nav-prev');
        const navCarousel = element.querySelector('.Carousel__nav');
        const slides = element.querySelectorAll('.swiper-wrapper .swiper-slide');
        //const pagination = element.querySelector('.Carousel__pagination');
        const viewTwo = parseInt(element.getAttribute('data-view'), 10) || 1;
    
        this.swiperFour = new Swiper(element, {
          slidesPerView: 2,
          loop: false,
          //focusOnSelect:true,
          spaceBetween: 12,
          breakpoints: {
            1: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            768: {
              slidesPerView: viewTwo,
              spaceBetween: 24,
            },
            //1024: {
              //slidesPerView: 4,
              //spaceBetween: 24,
            //}
          },
          navigation: {
            nextEl: nextArrow,
            prevEl: prevArrow
          },
          on: {
            breakpoint: function (e) {
              //console.log('swiper per view - list4: ', e.passedParams.breakpoints[e.currentBreakpoint].slidesPerView);
              //console.log('total slides - list4: ', slides.length);
              if ( e.passedParams.breakpoints[e.currentBreakpoint].slidesPerView >= slides.length) {
                navCarousel.style.display = 'none';
              } else {
                navCarousel.style.display = 'flex';
              }
            },
          },
          //pagination: {
            //el: pagination,
            //clickable: true,
          //},
        });
      });

      elementsThree.forEach((element) => {
        const nextArrow = element.querySelector('.Carousel__nav-next');
        const prevArrow = element.querySelector('.Carousel__nav-prev');
        const navCarousel = element.querySelector('.Carousel__nav');
        const slides = element.querySelectorAll('.swiper-wrapper .swiper-slide');
        //const pagination = element.querySelector('.Carousel__pagination');
        const viewThree = parseInt(element.getAttribute('data-view'), 10) || 1;
    
        this.swiperFour = new Swiper(element, {
          slidesPerView: 1,
          loop: false,
          //focusOnSelect:true,
          spaceBetween: 12,
          breakpoints: {
            1: {
              slidesPerView: 1,
              spaceBetween: 12,
            },
            768: {
              slidesPerView: viewThree,
              spaceBetween: 24,
            },
            //1024: {
              //slidesPerView: 4,
              //spaceBetween: 24,
            //}
          },
          navigation: {
            nextEl: nextArrow,
            prevEl: prevArrow
          },
          on: {
            breakpoint: function (e) {
              //console.log('swiper per view - list4: ', e.passedParams.breakpoints[e.currentBreakpoint].slidesPerView);
              //console.log('total slides - list4: ', slides.length);
              if ( e.passedParams.breakpoints[e.currentBreakpoint].slidesPerView >= slides.length) {
                navCarousel.style.display = 'none';
              } else {
                navCarousel.style.display = 'flex';
              }
            },
          },
          //pagination: {
            //el: pagination,
            //clickable: true,
          //},
        });
      });

      elementsFour.forEach((element) => {
        const nextArrow = element.querySelector('.Carousel__nav-next');
        const prevArrow = element.querySelector('.Carousel__nav-prev');
        const navCarousel = element.querySelector('.Carousel__nav');
        const slides = element.querySelectorAll('.swiper-wrapper .swiper-slide');
        //const pagination = element.querySelector('.Carousel__pagination');
        const viewFour = parseInt(element.getAttribute('data-view'), 10) || 1;
    
        this.swiperFour = new Swiper(element, {
          slidesPerView: 2,
          loop: false,
          //focusOnSelect:true,
          spaceBetween: 12,
          breakpoints: {
            1: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 24,
            },
            1024: {
              slidesPerView: viewFour,
              spaceBetween: 24,
            }
          },
          navigation: {
            nextEl: nextArrow,
            prevEl: prevArrow
          },
          on: {
            breakpoint: function (e) {
              //console.log('swiper per view - list4: ', e.passedParams.breakpoints[e.currentBreakpoint].slidesPerView);
              //console.log('total slides - list4: ', slides.length);
              if ( e.passedParams.breakpoints[e.currentBreakpoint].slidesPerView >= slides.length) {
                navCarousel.style.display = 'none';
              } else {
                navCarousel.style.display = 'flex';
              }
            },
          },
          //pagination: {
            //el: pagination,
            //clickable: true,
          //},
        });
      });

      elementsServices.forEach((element) => {
        const nextArrow = element.querySelector('.homeServices .Carousel__nav-next');
        const prevArrow = element.querySelector('.homeServices .Carousel__nav-prev');
        const navCarousel = element.querySelector('.homeServices .Carousel__nav');
        const slides = element.querySelectorAll('.homeServices .swiper-wrapper .swiper-slide');
        //const pagination = element.querySelector('.homeServices .Carousel__pagination');
    
        this.swiperServices = new Swiper(element, {
          slidesPerView: 2,
          spaceBetween: 12,
          loop: false,
          centerInsufficientSlides: true,
          watchOverflow: true,
          observer: true,
          //focusOnSelect:true,
          breakpoints: {
            1: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 6,
            }
          },
          navigation: {
            nextEl: nextArrow,
            prevEl: prevArrow
          },
          //pagination: {
          //  el: pagination,
          //  clickable: true,
          //},
          on: {
            breakpoint: function (e) {
              //console.log('swiper per view: ', e.passedParams.breakpoints[e.currentBreakpoint].slidesPerView);
              //console.log('total slides: ', slides.length);
              if ( e.passedParams.breakpoints[e.currentBreakpoint].slidesPerView >= slides.length) {
                navCarousel.style.display = 'none';
              } else {
                navCarousel.style.display = 'flex';
              }
            },
          },
        });
        /*
        if (window.innerWidth >= 1024) {
          if (slides.length < 7) {
            navCarousel.style.display = 'none';
          }        
        } else if ( (window.innerWidth < 1024) & (window.innerWidth >= 768) ) {
          if (slides.length < 4) {
            navCarousel.style.display = 'none';
          } 
        } else if ( (window.innerWidth < 768) ) {
          if (slides.length < 3) {
            navCarousel.style.display = 'none';
          } 
        } else {
          
        }
        */
      });

      elementsFilters.forEach((element) => {
        const nextArrow = element.querySelector('.filtersBlock .Carousel__nav-next');
        const prevArrow = element.querySelector('.filtersBlock .Carousel__nav-prev');
        const navCarousel = element.querySelector('.filtersBlock .Carousel__nav');
        const slides = element.querySelectorAll('.filtersBlock .swiper-wrapper .swiper-slide');
        //const pagination = element.querySelector('.filtersBlock .Carousel__pagination');
    
        this.swiperServices = new Swiper(element, {
          slidesPerView: 2,
          spaceBetween: 12,
          loop: false,
          centerInsufficientSlides: true,
          watchOverflow: true,
          observer: true,
          //focusOnSelect:true,
          breakpoints: {
            1: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 5,
            }
          },
          navigation: {
            nextEl: nextArrow,
            prevEl: prevArrow
          },
          //pagination: {
          //  el: pagination,
          //  clickable: true,
          //},
          on: {
            breakpoint: function (e) {
              //console.log('swiper per view: ', e.passedParams.breakpoints[e.currentBreakpoint].slidesPerView);
              //console.log('total slides: ', slides.length);
              if ( e.passedParams.breakpoints[e.currentBreakpoint].slidesPerView >= slides.length) {
                navCarousel.style.display = 'none';
              } else {
                navCarousel.style.display = 'flex';
              }
            },
          },
        });
        /*
        if (window.innerWidth >= 1024) {
          if (slides.length < 7) {
            navCarousel.style.display = 'none';
          }        
        } else if ( (window.innerWidth < 1024) & (window.innerWidth >= 768) ) {
          if (slides.length < 4) {
            navCarousel.style.display = 'none';
          } 
        } else if ( (window.innerWidth < 768) ) {
          if (slides.length < 3) {
            navCarousel.style.display = 'none';
          } 
        } else {
          
        }
        */
      });

    //});

  }
}
