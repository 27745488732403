export default class Filter {
  init() {
    const dropdownOptions = document.getElementById("dropdown-options");
    document.querySelectorAll(".mobile-btn").forEach((btn) => {
      btn.addEventListener("click", (event) => {
        dropdownOptions.classList.toggle("active");
      });
    });
  }
}
